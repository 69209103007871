<template>

    <body>

        <!-- preloader -->
        <Loader> </Loader>
        <!-- preloader -->
        <div class="frame">
            <div class="site-wrapper overflow-hidden">

                <!-- Header Section -->
                <Affiliate-Header> </Affiliate-Header>
                <!-- <section class="hero_section new-changes">
                        <img src="../../assets/affiliate/images/affiliate-marketing.jpg" width="100%" style="position: absolute;" alt="Affiliate-Marketing">
                </section> -->

                <section class="profilepage">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="col-lg-7 text-center mx-auto position-relative">
                                    <div class="completed_stripe_onboarding" v-if="completed_stripe_onboarding == true">
                                        <h6>Your Account Is Connected Transfer Money</h6>
                                        <button type="button" @click="StripeTransferRequest('stripe_request')"
                                            class="btn btn-success add-comman-btn">
                                            Click Here
                                        </button>
                                    </div>
                                    <div class="completed_stripe_onboarding" v-else>
                                        <!-- <router-link to="/affiliate/account" class="btn btn-success add-comman-btn" data-aos="fade-up" data-aos-duration="1200"
                                                data-aos-delay="400">Link or Sign up Stripe Account</router-link> -->
                                        <button type="button" @click="StripeSetting('stripe_connect')"
                                            class="btn btn-success add-comman-btn">
                                            Link or Sign up Stripe Account
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <template>
                    <!-- Country -->
                    <div class="stripe_connect" id="stripe_connect" v-show="stripeConnectModal">
                        <b-col cols="12">
                            <b-form-group label="Select Country" label-for="country">
                                <validation-provider name="Country" rules="required" #default="{ errors }">
                                    <b-input-group class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                        <b-input-group-prepend is-text>
                                            <feather-icon icon="MapPinIcon" />
                                        </b-input-group-prepend>
                                        <b-form-select id="country" v-model="selectedCountry" :options="countryOptions"
                                            :state="errors.length > 0 ? false : null">
                                        </b-form-select>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <button type="button" @click="StripeConnect" class="btn btn-success add-comman-btn">
                            Process
                        </button>
                    </div>
                </template>

                <template>
                    <!-- Country -->
                    <div class="stripe_connect" id="stripe_request" v-show="stripeRequestModal">
                        <h1 class="stripe-withdraw">Your total Earning ${{ parseInt(this.total_earning) }} ready for withdraw</h1>
                        <h2 class="stripe-withdraw-amount">Withdraw or Transfer Amount</h2>
                        <b-col cols="12">
                            <b-form-group label="Enter Amount" label-for="Amount">
                                <validation-provider name="Amount" rules="required" #default="{ errors }">
                                    <b-input-group class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                        <b-input-group-prepend is-text>
                                            <feather-icon icon="DollerIcon" />
                                        </b-input-group-prepend>
                                        <b-form-input type="number" autocomplete="off" id="amount" v-model="amount"
                                            :state="errors.length > 0 ? false : null" />
                                        <!-- <b-form-select id="amount" v-model="amount" :options="countryOptions"
                                            :state="errors.length > 0 ? false : null">
                                        </b-form-select> -->
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <button type="button" @click="sendTransferRequest" class="btn btn-success add-comman-btn">
                            Process
                        </button>
                    </div>
                </template>

                <!-- Footer Section -->
                <Front-Footer> </Front-Footer>

            </div>
        </div>

    </body>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormText,
    BFormDatalist,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BImg,
} from 'bootstrap-vue'
import VueMeta from 'vue-meta'
import Header from './Affiliate-Header';
import Footer from './Front-Footer';
import inspired from './common/Inspired';
import AffiliateSubscription from './common/Affiliate-Subscription';
import Loader from './Loader';
import Vue from 'vue';
import store from '@/store';
import axios from 'axios';
import moment from 'moment';

import { db } from '@/main';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
    required
} from '@validations';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';


import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
Vue.use(VueMeta)


export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BFormCheckbox,
        BForm,
        BFormText,
        BButton,
        BFormDatalist,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormFile,
        BFormSelect,
        BFormTextarea,
        BImg,
        ValidationProvider,
        ValidationObserver,
        required,
        'Affiliate-Header': Header,
        'Front-Footer': Footer,
        'Affiliate-Subscription': AffiliateSubscription,
        'Inspired': inspired,
        'Loader': Loader,
        'VueSlickCarousel': VueSlickCarousel


    },
    data() {
        return {
            amount: '',
            total_earning: '',
            completed_stripe_onboarding: false,
            country_code: '',
            country: '',
            stripeConnectModal: false,
            stripeRequestModal: false,
            components: {},
            pages: {},
            slickOptions: {
                //options can be used from the plugin documentation
                slidesToShow: 3,
                infinite: true,
                meta_title: '',
                meta_description: '',
            },
            subscriptionss: [],
            selectedCountry: null,

            countryOptions: [
                { value: null, text: 'Please select a country' },
            ]
        }
    },
    metaInfo() {
        //alert(this.meta_title);
        return {
            title: localStorage.getItem("meta_title"),
            meta: [
                { name: 'description', content: localStorage.getItem("meta_description") },
            ]
        }
    },
    created: function () {
        this.getCountries();
        var connected_affiliate_id = this.$route.params.affiliate_id;
        if (connected_affiliate_id) {
            localStorage.setItem("connected_affiliate_id", connected_affiliate_id);
        }
        this.fetchAffiliateStripe();
    },

    methods: {

        fetchAffiliateStripe() {
            this.total_earning = localStorage.getItem("total_earning");
            var id = localStorage.getItem("connected_affiliate_id");
            // alert(id)
            this.countries = [];
            db.collection('affiliate_stripe_users').doc(id).get().then((doc) => {
                console.log(doc.data());
                this.item = doc.data();
                this.completed_stripe_onboarding = doc.data().completed_stripe_onboarding;
                console.log(this.completed_stripe_onboarding);
            })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        },

        getCountries() {
            this.countryOptions = [];

            db.collection("countries")
                .orderBy('country', 'asc')
                .get()
                .then((querySnapshot) => {
                    //console.log('hhhh1');
                    querySnapshot.forEach((doc) => {
                        this.countryOptions.push({
                            value: doc.id,
                            text: doc.data().country,
                        });
                        console.log(doc.id, " => ", doc.data());
                        // alert()

                    });


                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        },

        StripeSetting(id) {
            // alert()
            this.stripeConnectModal = true;
            document.getElementById(id).scrollIntoView({
                behavior: "smooth"
            });
        },

        sendTransferRequest() {
            var i = 0;
            this.TransferRequest = [];
            console.log(this.amount)
            // store.dispatch('app/commitActivateLoader');
            var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
            var uid = localStorage.getItem("userSessionId");
            var connectedAffiliateId = localStorage.getItem("connected_affiliate_id");
            db.collection("affiliate_transfer_request")
                .where("user_id", "==", uid)
                .where("connected_affiliate_id", "==", connectedAffiliateId)
                .get()

                
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.TransferRequest.push({
                            index: i,
                            id: doc.id,
                            connected_affiliate_id: doc.data().connected_affiliate_id,
                            amount: doc.data().amount,
                            user_id: doc.data().user_id,
                            created: doc.data().created,
                            modified: doc.data().modified,
                        });
                        console.log(this.TransferRequest)
                    })
                    console.log(i)
                    if (this.TransferRequest[i]) {
                        console.log(this.TransferRequest[i].connected_affiliate_id)
                        console.log(this.subscription_name)
                        if (this.TransferRequest[i].connected_affiliate_id == connectedAffiliateId) {
                            // alert("You Have Already Apply Cancelation Request For This Subscription!")
                            this.showNotification('Error! You Have Already Submit Transfer Request For This!', 'AlertTriangleIcon', 'danger');
                        }
                    } else {
                        db.collection("affiliate_transfer_request")
                            .add({
                                user_id: uid,
                                connected_affiliate_id: connectedAffiliateId,
                                amount: this.amount,
                                created: date,
                                modified: date,
                            })
                            .then((doc) => {
                                console.log("Request Send Successfully!");
                                store.dispatch('app/commitDeactivateLoader');
                                this.showNotification('Success, Request Send SuccessFully!', 'AlertTriangleIcon', 'success');
                                // this.$router.push({ name: 'affiliate-sharing-list' });
                            })
                    }
                    i++;
                })
        },

        StripeTransferRequest(id) {
            this.stripeRequestModal = true;
            document.getElementById(id).scrollIntoView({
                behavior: "smooth"
            });
        },

        StripeConnect: function () {

            if (this.selectedCountry == null) {
                // this.submit();
                alert("Please select your country...!")
            } else {
                // alert(this.selectedCountry)
                var userData = JSON.parse(localStorage.getItem('userData'))

                var user_id = localStorage.getItem("userSessionId");

                var base_url = window.location.href;

                var main_url = window.location.origin;

                this.countries = [];

                db.collection('countries').doc(this.selectedCountry)
                    .get().then(snapshot => {
                        this.country = snapshot.data().country;
                        this.country_code = snapshot.data().code;
                        console.log(this.country)
                        console.log(this.country_code);
                        // console.log(snapshot.id);
                        // console.log('0dXGx4ujAIP4Z70J3SoL');

                        db.collection("users").doc(user_id).update({ country: snapshot.id });

                        store.dispatch('app/commitActivateLoader');
                        var date = new Date().toLocaleString();//.toISOString().slice(0, 10);

                        // const auth = {
                        //     fullName: userData.fullName,
                        //     username: userData.username,
                        //     email: userData.email,
                        //     returning_url: base_url,
                        //     created: date,
                        //     modified: date
                        // };

                        // db.collection("companies").doc(doc.id).update({ stores: stores });
                        db.collection("affiliate_stripe_users")
                            .add({
                                name: userData.fullName,
                                username: userData.username,
                                email: userData.email,
                                background_image: userData.avatar,
                                image: userData.avatar,
                                country: this.country,
                                country_code: this.country_code,
                                returning_url: base_url,
                                created: date,
                                modified: date
                            }).then(doc => {
                                db.collection("affiliate_stripe_users").doc(doc.id).update({ cid: doc.id });
                                console.log("Added Successfully!");
                                // send email to company with signed url from laravel api
                                const url = this.$VUE_APP_API_ENDPOINT + "affiliate-stripe-connect";
                                const emailData = {
                                    Id: doc.id,
                                    fullName: userData.fullName,
                                    userEmail: userData.email,
                                    userName: userData.username,
                                }
                                axios.post(url, emailData)
                                    .then(response => {
                                        console.log(response);
                                        console.log(response.data.status);
                                        if (response.status == 200) {
                                            this.showNotification('Success, Added Successfully!', 'AlignJustifyIcon', 'Success');
                                            window.location.href = response.data;
                                            store.dispatch('app/commitDeactivateLoader');
                                        } else {
                                            store.dispatch('app/commitDeactivateLoader');
                                            this.showNotification('Error! Email Failed', 'AlertTriangleIcon', 'Danger');
                                            // this.$router.push('/companies/index');
                                        }

                                    })
                                    .catch(error => {
                                        store.dispatch('app/commitDeactivateLoader');
                                        this.showNotification('Error writing document!', 'AlertTriangleIcon', 'Danger');
                                        console.log(error);
                                    });
                            })
                    }).catch(err => {
                        store.dispatch('app/commitDeactivateLoader');
                        console.error("Error writing document: ", err);
                        this.showNotification('Error writing document!', 'AlertTriangleIcon', 'Danger');
                    });
                // Correct username is 'foo' and password is 'bar'
                // const url = main_url + '/api/add-vendor';
                // this.success = false;
                // this.error = null;

                // try {
                //     const res = axios.post(url, { auth }).then(
                //         (res) => {
                //             console.log(res.data.url)
                //             store.dispatch('app/commitDeactivateLoader')
                //             // this.$router.push(res.data.url)
                //             window.location.href = res.data.url;
                //         });
                //     console.log(res);
                //     this.success = true;
                // } catch (err) {
                //     this.error = err.message;
                // }
            }
        },

        showNotification(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    variant,
                },
            })
        },
    }
}

</script>
<style>
/* afflialate */

@import "../../assets/affiliate/css/style.css";

@import "../../assets/affiliate/instruction-css/animate.css";

/* @import "../../assets/affiliate/instruction-css/bootstrap.min.css"; */

@import "../../assets/affiliate/instruction-css/font-awesome.min.css";

@import "../../assets/affiliate/instruction-css/icofont.min.css";

@import "../../assets/affiliate/instruction-css/responsive.css";

.main-page {
    display: inline-block;
    white-space: nowrap;
    /* width: 409px;
    height: 65px; */
    font-family: Cinzel;
    font-weight: 700;
    font-size: 48px;
    line-height: 90px;
    font-style: normal;
    text-transform: uppercase;
}

.hot-picks-section {
    padding: 10vh 0;
}

.hot-picks-section .section-title {
    text-align: center;
    font-weight: 900;
    margin-bottom: 50px;
}

.hot-picks-section .Card {
    margin: 0 10px;
    position: relative;
}

.hot-picks-section .Card_info {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.hot-picks-section .Card img {
    border-radius: 8px;
    overflow: hidden;
    height: 400px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    object-fit: cover;
}

.hot-picks-section .Card .card-title {
    margin-top: 0;
    font-size: 20px;
    color: #fff;
    margin-bottom: 1px;
}

.hot-picks-section .Card:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    border-radius: 8px;
    background-image: linear-gradient(to top,
            #000000,
            #000000,
            #857d7d00,
            #b9b9b9,
            #ffffff00);
}

.hot-picks-section .Card .card-info {
    font-size: 14px;
    color: #fff;
    margin-bottom: 3px;
    margin-top: 0;
}

@media (max-width:767px) {
    section.hero_section.new-changes {
        height: 84vh;
    }

    section.hero_section img {
        max-height: initial !important;
        height: 100%;
        object-fit: cover;
    }
    .stripe-withdraw{
        font-size: 15px;
        margin-top: -50px;
    }
    .stripe-withdraw-amount{
        font-size: 15px;
    }
    /* div#__BVID__54 {
        margin-top: 50px;
    } */
    .stripe_connect{
        margin-top: 50px;
    }
    button.btn.btn-success.add-comman-btn {
        margin-top: 20px;
    }
    .footer-section {
        padding: 60px 15px 10px;
        margin-top: 50px;
    }
    .completed_stripe_onboarding  {
        margin-top: 0;
    }
    .header-btn {
        background-color: unset !important;
    }
    .navbar-top.d-flex.justify-content-between {
        height: 60px;
    }
    .stripe-withdraw {
        margin-top: 47px;
        line-height: 27px;
    }
    #country {
        margin-top: 0;
    }
}

.stripe_connect {
    text-align: center;
    padding: 10%;
}

.completed_stripe_onboarding {
    margin-top: 10%;
}
#country{
    min-height: 40px;
    margin-left: 0;
}
</style>
